import Typography from "typography"
import './global.css';

const typography = new Typography({
  overrideStyles: () => ({
    a: {
      color: 'var(--textLink)',
    },
    'h1, h2, h3, h4, h5, h6': {
      color: 'var(--textTitle)'
    },
    hr: {
      background: 'var(--hr)',
    },
    'a.gatsby-resp-image-link': {
      boxShadow: 'none',
    },
    // These two are for gatsby-remark-autolink-headers:
    'a.anchor': {
      boxShadow: 'none',
    },
    'a.anchor svg[aria-hidden="true"]': {
      stroke: 'var(--textLink)',
    },
    'p code': {
      fontSize: '1rem',
    },
    // TODO: why tho
    'h1 code, h2 code, h3 code, h4 code, h5 code, h6 code': {
      fontSize: 'inherit',
    },
    'li code': {
      fontSize: '1rem',
    },
    blockquote: {
      color: 'inherit',
      borderLeftColor: 'inherit',
      opacity: '0.8',
    },
    'blockquote.translation': {
      fontSize: '1em',
    },
    code: {
      textShadow: 'none !important'
    }
  }),
  baseLineHeight: 1.666,
  headerFontFamily: [
    "Bowlby One SC",
    "Black Han Sans",
    // "sans-serif",
  ],
  bodyFontFamily: ["Open Sans", "serif"],
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale

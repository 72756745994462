import React from "react"
import { Link } from "gatsby"

import Footer from "./footer"
import { rhythm, scale } from "../utils/typography"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1.5),
          marginBottom: rhythm(1.5),
          marginTop: 0,
          color: 'var(--textTitle)'
        }}
      >
        <Link
          style={{
            color: 'inherit',
            textDecoration: 'none'
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h1
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: rhythm(2),
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: 'none'
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        color: 'var(--textNormal)',
        background: 'var(--bg)',
        transition: 'color 0.2s ease-out, background 0.2s ease-out',
        minHeight: '100vh',
      }}
    >
      <header>{header}</header>
      <main>{children}</main>
      {location.pathname === rootPath ? <Footer /> : null}
    </div>
  )
}

export default Layout
